/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // FitVids
        $(".fitvideo").fitVids();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // JavaScript to close other navbars when a second is opened
        $(function () {
            /*$('.navbar-collapse').on('show.bs.collapse', function (e) {
                $('.navbar-collapse').not(this).collapse('hide');
                // $('#menu-boats li.dropdown').addClass('open')
            });
            $('#top-menu-navbar-collapse-boats').on('shown.bs.collapse', function (e) {
                $('#menu-boats .dropdown-toggle').dropdown('toggle', 'open').show();
                //console.log('shown:', e);
            });*/
        });

        // JavaScript to flip cards (ex. boat accesories)
        $('.flip').hover(function(){
          $(this).find('.card').toggleClass('flipped');
        });

        $('input#mc_mv_EMAIL').attr("placeholder", "your e-mail");
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        // Initialize Swiper
        var swiper = new Swiper('#headline-swiper-container', {
          pagination: '.swiper-pagination',
          paginationClickable: true,
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
          spaceBetween: 30,
          slidesPerView: 5,
          // Responsive breakpoints
          breakpoints: {
            // when window width is <= ...
            750: {
              slidesPerView: 1,
              spaceBetween: 10
            },
            1000: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            1500: {
              slidesPerView: 3,
              spaceBetween: 30
            },
            1800: {
              slidesPerView: 4,
              spaceBetween: 30
            }
          }
        });
      }
    },

    // About us page, note the change from about-us to about_us.
    'page_template_template_contact_us': {
      finalize: function() {
        
        $('#map_contact').addClass('scrolloff');                // set the mouse events to none when doc is ready
        
        $('#overlay').on("mouseup",function(){          // lock it when mouse up
            $('#map_contact').addClass('scrolloff'); 
            //somehow the mouseup event doesn't get call...
        });
        $('#overlay').on("mousedown",function(){        // when mouse down, set the mouse events free
            $('#map_contact').removeClass('scrolloff');
        });

        $("#map_contact").mouseleave(function () {              // becuase the mouse up doesn't work... 
            $('#map_contact').addClass('scrolloff');            // set the pointer events to none when mouse leaves the map area
                                                        // or you can do it on some other event
        });
        
      }
    },
    // Find a boat
    'page_template_template_find_a_boat': {
      finalize: function() {
        
      	$('#drop-arrow-mod').on('show.bs.dropdown', function () {
    		    $('#rotate_arrow').toggleClass('rotated');
    		    $('#drop-arrow-mod').toggleClass('black');
    		});

    		$('#drop-arrow-mod').on('hidden.bs.dropdown', function () {
    	    	$('#rotate_arrow').toggleClass('rotated');
    	    	$('#drop-arrow-mod').toggleClass('black');
    		});  
            
        // Add slideDown animation to Bootstrap dropdown when expanding.
        $('#drop-arrow-mod').on('show.bs.dropdown', function() {
          $(this).find('.dropdown-menu').first().stop(true, true).slideDown();
        });
      
        // Add slideUp animation to Bootstrap dropdown when collapsing.
        $('#drop-arrow-mod').on('hide.bs.dropdown', function() {
          $(this).find('.dropdown-menu').first().stop(true, true).slideUp();
        });
        
        
  
        // FILTER BUTTONS
        // 1. Type, Seats and Stability
        $(".filter_groups button").click(function() {
          var button_id  = $(this).attr("id");
          var button_group = button_id.substring(0,4);
  
          // BUTTONS
          $(".filter_groups button[id^='" + button_group + "'][disabled!='disabled']").not("#"+button_id).removeClass('selected');
          $(this).toggleClass('selected');


          // BOATS
          $(".boats li").addClass('hide');

          // SHOW ONLY BUTTONS SELECTED
          var ids_selected = "";
          $(".filter_groups button[class*='selected']").each(function(){
              ids_selected += "." + $(this).attr('id');
          });

          //Type and Seats
          $(".boats li" + ids_selected).removeClass('hide');
        });

                
        // RESET BUTTON
        $(".filter_groups button#reset").click(function() {
          // Filter buttons
          $(".filter_groups button").removeClass('selected');
          // Clear Kg
          $("input#kg").val('');
          // Sizes buttons
          $(".sizes-boat button").removeClass('hide');
          // Show all boats
          $('.boats li').removeClass('hide');
          $(".sizes-boat button").show();
          // Hide old models
          $(".boats li.older_boat").addClass('hide');
        });


        // SHOW OLDER BUTTON
        $("button#older_models").click(function() {
          $("section.boats ul.ul-1").toggleClass('hide');
        });


        // Clear Kg
        $(".filter_groups button").click(function() {
          // Clear Kg
          $("input#kg").val('');
          // Sizes buttons
          $(".sizes-boat button").removeClass('hide');
        });

      }
    },

    // Training Center
    'single_training_center': {
      finalize: function() {
        
        $('#drop-arrow-mod').on('show.bs.dropdown', function () {
            $('#rotate_arrow').toggleClass('rotated');
            $('#drop-arrow-mod').toggleClass('black');
            
        });

        $('#drop-arrow-mod').on('hidden.bs.dropdown', function () {
            $('#rotate_arrow').toggleClass('rotated');
            $('#drop-arrow-mod').toggleClass('black');
        });  
            
        // Add slideDown animation to Bootstrap dropdown when expanding.
        $('#drop-arrow-mod').on('show.bs.dropdown', function() {
          $(this).find('.dropdown-menu').first().stop(true, true).slideDown();
        });
      
        // Add slideUp animation to Bootstrap dropdown when collapsing.
        $('#drop-arrow-mod').on('hide.bs.dropdown', function() {
          $(this).find('.dropdown-menu').first().stop(true, true).slideUp();
        });
      }
    },
    // Events
    'page_template_template_events': {
      finalize: function() {

        $('#checkboxInputInt').change(function() {
          if($(this).is(":checked")) {
            $(".international").show();
            var category = $( "#select-discipline" ).val();
            
            if (category === "all-disciplines"){
              $(".event-list").show();
            }
            else{
              $(".event-list").hide();
              $(".event-list."+ category).show();
            }
          }
          else{
            $(".international").hide();
          }
        });
      
      
        $('#checkboxInputLocal').change(function() {
          if($(this).is(":checked")) {
            $(".local").show();
            var category = $( "#select-discipline" ).val();
            
            if (category === "all-disciplines"){
              $(".event-list").show();
            }
            else{
              $(".event-list").hide();
              $(".event-list."+ category).show();
            }
          }
          else{
            $(".local").hide();
          }
        });
        
        
        $('#select-discipline').on('change', function() {
          if (this.value === "all-disciplines"){
            $(".event-list").show();
            $(".division").show();
          }
          
          else{
            $(".event-list").hide();
            $(".division").hide();
            $(".event-list."+ this.value).show();
          }
          
          if (!$('#checkboxInputInt').is(":checked")){
            $(".international").hide();
          }
          
          if (!$('#checkboxInputLocal').is(":checked")){
            $(".local").hide();
          }
      
        });
        
      }
    },
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


  
  
})(jQuery); // Fully reference jQuery after this point.